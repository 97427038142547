<template>
  <section id="hero">
    <base-jumbotron
      :src="require('@/assets/bg.jpg')"
      color="grey darken-4"
      dark
      height="100%"
      min-height="100vh"
    >
      <v-row
        align="center"
        class="pb-12"
      >
        <v-col
          cols="12"
          md="5"
        >
          <div>
            <base-title>
              Entrez dans le Web 3.0 avec StonckNow
            </base-title>

            <v-divider class="mb-3" />

            <div class="subheading mb-4">
              <p>Comprenez enfin ce qui semblait incomprénsible </p>

              <p>
                La meuilleur facon d'intégrez le monde des crypto,NFT,des technologies de demain
              </p>
            </div>

            <v-btn
              color="primary"
              class="ma-0"
              large
            >
              Commencer
              <v-icon right>
                mdi-school
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </base-jumbotron>
  </section>
</template>

<script>
  export default {
    name: 'HomeHero',
  }
</script>
